import React from 'react';
import CustomCircularProgress from '../src/components/Progress/CustomCircularProgress';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { useRouter } from 'next/router';

const Index = () => {
  const router = useRouter();
  const { user } = useAuth0();
  const isAdmin =
    user?.email?.split('@').pop() === 'planetmarathi.com' && user?.org_id === process.env.NEXT_PUBLIC_AUTH0_ORG_ID;
  if (user && isAdmin) {
    router.push('admin/dashboard');
  } else if (user) {
    router.push('vendor/all-projects');
  }
  return (
    <>
      <div className="center">
        <CustomCircularProgress color="secondary" />
      </div>
    </>
  );
};
export default withAuthenticationRequired(Index);
